import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute } from 'Utils/private-route'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
const Login = React.lazy(() => import('./Containers/Login'))
const Chat = React.lazy(() => import('./Containers/Chat'))
const loginCode = React.lazy(() => import('./Containers/LoginCode'))

function App() {
  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={''}>
          <Switch>
            <PrivateRoute authed={true} path="/chat" component={Chat} />
            <Route path="/login" component={Login} />
            <Route path="/" component={loginCode} />
            <Redirect from="/" to="/login" />
          </Switch>
          <NotificationContainer />
        </React.Suspense>
      </BrowserRouter>
    </>
  )
}

export default App
