import {
  AUTH,
  AUTH_RESPONSE,
  FETCH_DETAILS,
  FETCH_DETAILS_RESPONSE,
  FETCH_MESSAGES,
  FETCH_MESSAGES_RESPONSE,
  USER_DETAILS,
  USER_DETAILS_RESPONSE,
  GET_IP_RESPONSE,
  GET_IP,
} from './chat.types'

/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author Akhil Francis<akhil@trillium-technologies.com>

 * return(
 type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from login.type.js,
  payload: object - contains params,
})
 */

/**
 * clickLogin-Action w.r.t AUTH(type) in the redux
 * @description - Action used to call the login API
 * @param logData
 * @returns {{payload: *, type: string}}
 */
export const authenticate = (logData) => ({
  type: AUTH,
  payload: logData,
})
/**
 * authResponse - Action w.r.t AUTH_RESPONSE(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const authResponse = (user) => ({
  type: AUTH_RESPONSE,
  payload: user,
})

/**
 * fetchDetails - Action w.r.t FETCH_DETAILS(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const fetchDetails = (user) => ({
  type: FETCH_DETAILS,
  payload: user,
})

/**
 * fetchDetailsResponse - Action w.r.t FETCH_DETAILS_RESPONSE(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const fetchDetailsResponse = (user) => ({
  type: FETCH_DETAILS_RESPONSE,
  payload: user,
})

/**
 * fetchMessages - Action w.r.t FETCH_DETAILS(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const fetchMessages = (data) => ({
  type: FETCH_MESSAGES,
  payload: data,
})

/**
 * fetchMessagesResponse - Action w.r.t FETCH_DETAILS(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const fetchMessagesResponse = (data) => ({
  type: FETCH_MESSAGES_RESPONSE,
  payload: data,
})

/**
 * fetchMessagesResponse - Action w.r.t FETCH_DETAILS(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const userDetails = (data) => ({
  type: USER_DETAILS,
  payload: data,
})

/**
 * fetchMessagesResponse - Action w.r.t FETCH_DETAILS(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const userDetailsResponse = (data) => ({
  type: USER_DETAILS_RESPONSE,
  payload: data,
})

/**
 * fetchMessagesResponse - Action w.r.t FETCH_DETAILS(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const getIpaddress = () => ({
  type: GET_IP,
})
/**
 * fetchMessagesResponse - Action w.r.t FETCH_DETAILS(type) in the redux
 * @description - Action used to get the response of login API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const getIpaddressResponse = (data) => ({
  type: GET_IP_RESPONSE,
  payload: data,
})
