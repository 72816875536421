/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const AUTH = 'AUTH'
export const AUTH_RESPONSE = 'AUTH_RESPONSE'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const FETCH_DETAILS = 'FETCH_DETAILS'
export const FETCH_DETAILS_RESPONSE = 'FETCH_DETAILS_RESPONSE'
export const FETCH_MESSAGES = 'FETCH_MESSAGES'
export const FETCH_MESSAGES_RESPONSE = 'FETCH_MESSAGES_RESPONSE'
export const USER_DETAILS = 'USER_DETAILS'
export const USER_DETAILS_RESPONSE = 'USER_DETAILS_RESPONSE'
export const GET_IP = 'GET_IP'
export const GET_IP_RESPONSE = 'GET_IP_RESPONSE'
