import { combineReducers } from 'redux'
import chatReducer from './Chat/chat.reducer'
/** rootReducer
 * @fileOverview Combine  all reducer action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 */

const rootReducer = combineReducers({
  chat: chatReducer,
})

export default rootReducer
